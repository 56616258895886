var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PolygonMaskPlugin_engine;
import { PolygonMask } from "./Options/Classes/PolygonMask";
import { PolygonMaskInstance } from "./PolygonMaskInstance";
import { isSsr } from "tsparticles-engine";
class PolygonMaskPlugin {
    constructor(engine) {
        _PolygonMaskPlugin_engine.set(this, void 0);
        this.id = "polygonMask";
        __classPrivateFieldSet(this, _PolygonMaskPlugin_engine, engine, "f");
    }
    getPlugin(container) {
        return new PolygonMaskInstance(container, __classPrivateFieldGet(this, _PolygonMaskPlugin_engine, "f"));
    }
    needsPlugin(options) {
        var _a, _b, _c;
        return ((_b = (_a = options === null || options === void 0 ? void 0 : options.polygon) === null || _a === void 0 ? void 0 : _a.enable) !== null && _b !== void 0 ? _b : (((_c = options === null || options === void 0 ? void 0 : options.polygon) === null || _c === void 0 ? void 0 : _c.type) !== undefined && options.polygon.type !== "none"));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(source)) {
            return;
        }
        const optionsCast = options;
        let polygonOptions = optionsCast.polygon;
        if ((polygonOptions === null || polygonOptions === void 0 ? void 0 : polygonOptions.load) === undefined) {
            optionsCast.polygon = polygonOptions = new PolygonMask();
        }
        polygonOptions.load(source === null || source === void 0 ? void 0 : source.polygon);
    }
}
_PolygonMaskPlugin_engine = new WeakMap();
export async function loadPolygonMaskPlugin(engine) {
    if (!isSsr() && !("SVGPathSeg" in window)) {
        await import("./pathseg.js");
    }
    const plugin = new PolygonMaskPlugin(engine);
    await engine.addPlugin(plugin);
}
export * from "./Enums/PolygonMaskInlineArrangement";
export * from "./Enums/PolygonMaskMoveType";
export * from "./Enums/PolygonMaskType";
export * from "./Options/Interfaces/IPolygonMaskOptions";
